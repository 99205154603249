import axios, { AxiosRequestConfig } from "axios";

const fetchClient = () => {
	const defaultOptions: AxiosRequestConfig = {
		baseURL: "https://risq-api.herokuapp.com/api",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*"
		}
	};

	// Create instance
	const instance = axios.create(defaultOptions);

	return instance;
};

export default fetchClient();
