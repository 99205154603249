import styled, { css } from "styled-components";

export const StyledPick = styled.div`
	color: #3a3a3a;

	.fixture {
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 600;
		font-size: 0.9rem;

		.v {
			margin: 0 4px;
		}
	}

	.options {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	hr {
		border: none;
		border-bottom: solid 1px lightgrey;
		margin: 20px 17px;
	}
`;

export const Option = styled.div<{ active?: boolean }>`
	font-weight: 600;
	font-size: 0.65rem;
	border: lightgrey solid 1px;
	padding: 5px 7px;
	border-radius: 15px;
	margin-bottom: 5px;

	${({ active }) =>
		active &&
		css`
			background: green;
			color: white;
		`}
`;
