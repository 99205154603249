import { useEffect, useState } from "react";
import axios from "./axios";

const useDraws = (id?: number) => {
	const [draws, setDraws] = useState();
	const [draw, setDraw] = useState();

	useEffect(() => {
		if (id) {
			axios.get(`/draw/${id}`).then(res =>
				setDraw({
					...res.data,
					time: new Date(res.data.closeTime),
					pick: res.data.definition.groups[0].events.length
				})
			);
		} else {
			axios.get("/draws/open").then(res => {
				setDraws([
					...res.data.map((drawFromApi: any) => ({
						...drawFromApi,
						time: new Date(drawFromApi.closeTime),
						pick: drawFromApi.definition.groups[0].events.length
					}))
				]);
			});
		}
	}, [id]);

	return [draws, draw];
};

export default useDraws;
