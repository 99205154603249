import React, { useContext } from "react";

import { Redirect } from "react-router-dom";
import { StyledSummary } from "./style";
import PickReceipt from "../../components/PickReceipt";
import StickyButton from "../../components/StickyButton";
import { ContestContext } from "../../contexts/ContestContext";

const Summary: React.FC = () => {
	const { pickId, picks } = useContext(ContestContext);

	if (!pickId) return <Redirect to="/contests" />;

	return (
  <>
    <StyledSummary>
      <div className="entry-message">
					You have entered the $5M Jackpot Lottery
      </div>
      <div className="summary-message">
					here&apos;s a summary of your picks:
      </div>
      {picks &&
					picks.map(pick => (
  <PickReceipt
    fixture={pick.fixture}
    choice={pick.choice}
  />
					))}
    </StyledSummary>
    <StickyButton to="/picks">edit</StickyButton>
  </>
	);
};

export default Summary;
