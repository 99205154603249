import styled from "styled-components";

export const StyledContestCard = styled.div`
	background: white;
	height: 120px;
	margin: 5px 12px;
	margin-bottom: 12px;
	border-radius: 7px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: #3a3a3a;
	box-shadow: -4px 10px 32px 0px rgba(41, 41, 41, 0.16);

	.top-container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 78px;
		justify-content: center;
		padding-top: 5px;

		.prize {
			font-weight: 600;
			font-size: 1.8rem;
		}

		.no-of-picks {
			font-weight: 600;
			text-transform: uppercase;
			font-size: 0.75rem;
		}

		.sport-icon {
			position: absolute;
			background: green;
			height: 50px;
			width: 100px;
			right: -35px;
			top: -10px;
			transform: rotate(45deg);

			svg {
				margin-top: 20px;
				margin-left: 37px;
				height: 23px;
				width: 23px;
				transform: rotate(-45deg);
			}
		}
	}

	.card-footer {
		display: flex;
		justify-content: space-between;
		height: 20px;
		align-items: center;
		padding: 8px 16px;
		background: #ebebeb;
		font-size: 0.75rem;
		font-weight: 700;
		text-transform: capitalize;

		.time-sport {
			display: flex;

			hr {
				margin: 0 10px;
				margin-top: 3px;
				border: none;
				border-left: lightgrey 1px solid;
				height: 10px;
			}
		}
	}
`;
