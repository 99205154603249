import React from "react";
import { Basketball, Football, Rugby, Soccer } from "../../assets/svgs";
import { StyledContestCard } from "./style";

const ContestCard: React.FC<{
	id?: number;
	picks: number;
	sport?: string;
	time?: Date;
	clickHandler?: React.Dispatch<number>;
}> = ({ id, picks, sport, time, clickHandler }) => {
	return (
  <StyledContestCard
    onClick={() => id && clickHandler && clickHandler(id)}
		>
    <div className="top-container">
      <div className="prize">$10 Million</div>
      <div className="no-of-picks">
					pick
        {` ${picks}`}
      </div>
      <div className="sport-icon">
        {sport === "Basketball" && <Basketball />}
        {sport === "Rugby" && <Rugby />}
        {sport === "Football" && <Football />}
        {sport === "Soccer" && <Soccer />}
      </div>
    </div>
    <div className="card-footer">
      <div className="time-sport">
        <div className="time">
          {time && time.toTimeString().substr(0, 5)}
        </div>
        <hr />
        <div className="sport">{sport && sport}</div>
      </div>
      <div className="stake">stake $2</div>
    </div>
  </StyledContestCard>
	);
};

export default ContestCard;
