import React, { useContext } from "react";

import { StyledPage } from "./style";
import { ContestContext } from "../../contexts/ContestContext";
import ContestCard from "../../components/ContestCard";
import useDraws from "../../hooks/useDraws";
import Link from "../../components/helpers/Link";

const productMap: { [key: string]: string } = {
	"soccer-lottery": "Soccer",
	"basketball-lottery": "Basketball",
	"rugby-lottery": "Rugby",
	"afl-lottery": "AFL",
	"football-lottery": "Football"
};

const Contests: React.FC = () => {
	const [draws] = useDraws();
	const { setPickId } = useContext(ContestContext);

	return (
  <StyledPage>
    <div className="date-picker">monday, jun 16</div>
    {draws &&
				draws.map((draw: any) => (
  <Link to="/picks">
    <ContestCard
      key={`card_${draw.drawId}`}
      id={draw.drawId}
      picks={draw.pick}
      sport={productMap[draw.productId]}
      time={draw.time}
      clickHandler={setPickId}
    />
  </Link>
				))}
  </StyledPage>
	);
};

export default Contests;
