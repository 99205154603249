import styled from "styled-components";

export const StyledPicks = styled.div`
	display: flex;
	flex-direction: column;
	background: red;

	.back-link {
		height: 45px;
		margin-top: 5px;
		display: flex;
		align-items: center;
		margin-left: 20px;
		color: white;
		text-transform: uppercase;
		font-size: 0.75rem;
		font-weight: 600;
	}

	.pick-prompt {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		color: white;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 0.75rem;
	}

	.pick-list {
		background: white;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		flex-grow: 1;
		box-shadow: -4px 10px 32px 0px rgba(41, 41, 41, 0.16);
	}
`;

export const Button = styled.button`
	position: sticky;
	bottom: 0px;
	justify-self: center;
	height: 50px;
	background: red;
	color: white;
	font-weight: 600;
	text-transform: capitalize;
	outline: none;
	border: none;
	width: 100%;
`;
