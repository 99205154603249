import styled from "styled-components";

export const StyledPickReceipt = styled.div`
	display: flex;
	justify-content: space-between;
	background: white;
	margin: 0px 12px;
	padding: 0px 10px;
	margin-bottom: 12px;
	height: 70px;
	border-radius: 7px;
	align-items: center;
	font-size: 0.9rem;
	font-weight: 600;
	color: #3a3a3a;
	box-shadow: -4px 10px 32px 0px rgba(41, 41, 41, 0.16);

	.fixture {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;
