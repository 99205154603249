import styled from "styled-components";

export const StyledPage = styled.div`
	background: red;
	padding-bottom: 10px;

	.date-picker {
		height: 45px;
		border-radius: 7px;
		background: white;
		margin: 12px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		color: #3a3a3a;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 0.9rem;
	}
`;
