import React from "react";
import { StyledPickReceipt } from "./style";

const PickReceipt: React.FC<{ fixture: string; choice: string }> = ({
	fixture,
	choice
}) => {
	return (
  <StyledPickReceipt>
    <div className="fixture">{fixture}</div>
    <div className="pick">{choice}</div>
  </StyledPickReceipt>
	);
};

export default PickReceipt;
