import React, { useState, useContext } from "react";

import { StyledPick, Option } from "./style";
import { ContestContext } from "../../contexts/ContestContext";

const Pick: React.FC<{
	fixture: string;
	brackets: string[];
	index: number;
}> = ({ fixture, brackets, index }) => {
	const { picks, setPickByIndex } = useContext(ContestContext);

	return (
  <StyledPick>
    <div className="fixture">{fixture}</div>
    <div className="options">
      {brackets.map((option: any) => (
        <Option
          onClick={() => {
							if (setPickByIndex)
								setPickByIndex(
									{
										choice: option.description,
										fixture,
										id: option.id
									},
									index,
									brackets.length
								);
						}}
          active={
							picks &&
							picks[index] &&
							picks[index].id === option.id
						}
        >
          {option.description}
        </Option>
				))}
    </div>
    <hr />
  </StyledPick>
	);
};

export default Pick;
