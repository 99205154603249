import styled from "styled-components";

export const StyledApp = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;

	.app {
		background: red;
		width: 100%;
		height: 100%;
		max-width: 414px;
		max-height: 750px;
	}
`;
