import React from "react";
import styled from "styled-components";
import Link from "./helpers/Link";

const Button = styled.button`
	position: sticky;
	bottom: 0px;
	justify-self: center;
	height: 50px;
	background: red;
	color: white;
	font-weight: 600;
	text-transform: capitalize;
	outline: none;
	border: none;
	width: 100%;
`;

const StickyButton: React.FC<{ children: string; to?: string }> = ({
	children,
	to
}) => {
	if (to)
		return (
  <Link to={to}>
    <Button>{children}</Button>
  </Link>
		);
	return <Button>{children}</Button>;
};

export default StickyButton;
