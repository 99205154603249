import React from "react";

const Football: React.FC = () => {
	return (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 512.004 512.004"
    height="512"
    viewBox="0 0 512.004 512.004"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
		>
    <path d="m495.914 25.367-2.383-6.895-6.894-2.383c-2.173-.75-54.049-18.366-126.54-15.842-42.613 1.484-83.581 9.661-121.767 24.304-47.767 18.317-91.196 46.813-129.081 84.699-37.885 37.885-66.382 81.314-84.699 129.081-14.642 38.185-22.819 79.154-24.303 121.767-2.524 72.483 15.091 124.366 15.842 126.54l2.383 6.895 6.895 2.383c2.028.701 47.344 16.089 112.305 16.089 4.644 0 9.399-.079 14.235-.248 42.613-1.484 83.581-9.661 121.767-24.304 47.768-18.317 91.196-46.813 129.081-84.699 37.885-37.885 66.382-81.314 84.698-129.081 14.644-38.186 22.82-79.154 24.305-121.767 2.523-72.483-15.093-124.366-15.844-126.539zm-26.221 16.944c4.428 16.005 14.021 57.396 12.04 109.693-.082 2.168-.19 4.329-.31 6.488-29.895-9.433-57.134-25.951-79.547-48.364s-38.93-49.652-48.364-79.547c2.158-.12 4.32-.228 6.488-.31 52.302-1.985 93.687 7.612 109.693 12.04zm-427.382 427.382c-4.428-16.005-14.022-57.397-12.04-109.693.082-2.168.19-4.33.31-6.488 29.895 9.434 57.134 25.951 79.547 48.364s38.931 49.652 48.364 79.547c-2.158.12-4.32.228-6.488.31-52.316 1.985-93.702-7.616-109.693-12.04zm339.23-88.152c-34.713 34.713-74.395 60.836-117.943 77.644-23.788 9.181-48.794 15.622-74.686 19.269-10.501-36.861-30.228-70.448-57.57-97.791s-60.929-47.07-97.791-57.571c3.647-25.891 10.087-50.898 19.268-74.686 16.808-43.548 42.931-83.23 77.644-117.943s74.395-60.836 117.943-77.644c23.788-9.181 48.794-15.622 74.686-19.269 10.501 36.861 30.228 70.448 57.571 97.791s60.93 47.07 97.79 57.571c-3.647 25.891-10.087 50.898-19.268 74.686-16.808 43.548-42.931 83.229-77.644 117.943z" />
    <path d="m319.701 171.09-21.239 21.238-21.239-21.238-21.213 21.213 21.239 21.238-21.247 21.248-21.239-21.239-21.213 21.213 21.239 21.239-21.248 21.247-21.239-21.238-21.212 21.212 21.238 21.239-21.238 21.239 21.212 21.213 21.239-21.239 21.239 21.239 21.213-21.213-21.239-21.239 21.248-21.248 21.239 21.239 21.213-21.212-21.239-21.239 21.247-21.248 21.239 21.239 21.213-21.213-21.239-21.239 21.239-21.238z" />
  </svg>
	);
};

export default Football;
