import styled from "styled-components";

export const StyledSummary = styled.div`
	display: flex;
	flex-direction: column;
	background: red;

	.entry-message {
		text-align: center;
		align-self: center;
		color: white;
		font-weight: 600;
		font-size: 1.2rem;
		max-width: 320px;
		height: 80px;
		display: flex;
		align-items: flex-end;
	}

	.summary-message {
		align-self: center;
		text-transform: uppercase;
		font-size: 0.8rem;
		color: white;
		font-weight: 600;
		height: 50px;
		display: flex;
		align-items: center;
	}
`;
