import React, { useContext, useState, useEffect } from "react";

import { Redirect } from "react-router-dom";
import { StyledPicks, Button } from "./style";

import ContestCard from "../../components/ContestCard";
import Pick from "../../components/Pick";
import Link from "../../components/helpers/Link";
import useDraws from "../../hooks/useDraws";
import { ContestContext } from "../../contexts/ContestContext";

const productMap: { [key: string]: string } = {
	"soccer-lottery": "Soccer",
	"basketball-lottery": "Basketball",
	"rugby-lottery": "Rugby",
	"afl-lottery": "AFL",
	"football-lottery": "Football"
};

const Picks: React.FC = () => {
	const { pickId } = useContext(ContestContext);
	const [, draw] = useDraws(pickId);
	const [events, setEvents] = useState();

	useEffect(() => {
		if (draw) setEvents([...draw.definition.groups[0].events]);
	}, [draw]);

	if (!pickId) return <Redirect to="/contests" />;

	if (draw)
		return (
  <>
    <StyledPicks>
      <Link to="/contests">
        <div className="back-link">
          {"<"}
          {' '}
list of events
        </div>
      </Link>
      <ContestCard
        picks={draw.pick}
        sport={productMap[draw.productId]}
        time={draw.time}
      />
      <div className="pick-prompt">
						pick a score for 
        {' '}
        {draw.pick}
        {' '}
fixtures
      </div>
      <div className="pick-list">
        {events &&
							events.map((event: any, i: number) => (
  <Pick
    index={i}
    fixture={event.description}
    brackets={[...event.brackets]}
  />
							))}
      </div>
    </StyledPicks>
    <Link to="/summary">
      <Button type="button">make predictions</Button>
    </Link>
  </>
		);
	return null;
};

export default Picks;
