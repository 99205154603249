import React, { useState, useEffect } from "react";

type setPickByIdType = (pick: any, index: number, length: number) => void;

interface ContextValueInterface {
	pickId?: number;
	setPickId?: React.Dispatch<number>;
	picks?: any[];
	setPickByIndex?: setPickByIdType;
}

export const ContestContext = React.createContext<ContextValueInterface>({});

export const ContestContextProvider: React.FC<{
	children: React.ReactNode;
	value?: ContextValueInterface;
}> = ({ children }) => {
	const [pickId, setPickId] = useState(0);
	const [picks, setPicks] = useState<any[]>([]);

	const setPickByIndex = (pick: any, index: number, length: number) => {
		if (picks && !(picks.length > 0)) {
			const picksArr = Array(length).fill({});
			picksArr[index] = { ...pick };
			setPicks([...picksArr]);
		} else {
			setPicks(prevState => {
				const newState = [...prevState];
				newState[index] = { ...pick };
				return newState;
			});
		}
	};

	useEffect(() => {
		setPicks([]);
	}, [pickId]);

	return (
  <ContestContext.Provider
    value={{ pickId, setPickId, picks, setPickByIndex }}
		>
    {children}
  </ContestContext.Provider>
	);
};
