import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { ContestContextProvider } from "./contexts/ContestContext";
import { Contests, Picks, Summary } from "./pages";
import { StyledApp } from "./style";

import ScrollToTop from "./components/helpers/ScrollToTop";

const App: React.FC = () => {
	return (
  <Router>
    <ScrollToTop>
      <ContestContextProvider>
        <StyledApp>
          <div className="app">
            <Route path="/contests" component={Contests} />
            <Route path="/picks" component={Picks} />
            <Route path="/summary" component={Summary} />
          </div>
        </StyledApp>
      </ContestContextProvider>
    </ScrollToTop>
  </Router>
	);
};

export default App;
