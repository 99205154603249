import React from "react";

const Rugby: React.FC = () => {
	return (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="467.98px"
    height="467.98px"
    viewBox="0 0 467.98 467.98"
    fill="white"
		>
    <g>
      <g>
        <path
          d="M429.319,87.299c-31.871-16.134-140.271-60.496-270.144,2.866c0,0-117.951,55.747-152.821,196.961
			c0,0-12.984,54.802-1.83,68.254c0,0,75.795,93.774,271.543,37.49c5.751-1.663,48.029-16.271,87.675-43.793
			c31.606-21.941,119.325-119.716,101.993-213.853c0,0-3.322-23.385-17.934-36.714C443.385,94.474,434.669,90,429.319,87.299z
			 M434.004,128.145c-26.441,100.524-117.098,164.327-117.098,164.327c-81.635,65.653-216.513,60.508-255.391,57.45
			c-5.963-0.469-9.355-5.658-7.402-11.316c25.231-73.084,65.396-114.749,82.676-130.151c4.464-3.985,12.713-4.442,15.673,0.763
			c2.813,4.945,5.256,11.395,7.035,16.767c1.885,5.683,6.366,6.814,10.409,2.402l12.16-13.267
			c4.044-4.412,6.164-12.733,3.196-17.931c-1.617-2.833-3.507-5.834-5.282-8.534c-3.288-5.009-2.418-12.042,2.557-15.371
			c1.978-1.318,4.082-2.703,6.075-3.989c5.025-3.256,13.018-2.959,16.607,1.839c1.084,1.445,2.172,2.966,3.212,4.45
			c3.43,4.907,9.874,6.037,14.615,2.38l5.849-4.516c4.743-3.657,5.873-10.66,1.867-15.118c-0.022-0.026-0.038-0.05-0.062-0.072
			c-4.005-4.458-3.188-10.315,2.308-12.703c2.347-1.02,4.854-2.078,7.214-3.06c5.524-2.31,13.871-1.735,17.018,2.896
			c3.149,4.635,9.213,6.604,14.065,4.065c4.854-2.533,5.979-6.961,3.326-10.652c-2.645-3.687-0.104-8.792,5.606-10.598
			c0.966-0.309,1.956-0.611,2.922-0.909c5.719-1.76,13.613-1.138,16.687,2.338c3.07,3.484,9.041,4.883,13.661,2.859
			c4.625-2.027,6.389-4.61,4.457-6.462s0.773-5.133,6.104-5.903c5.317-0.771,10.684,0.801,12.311,2.981
			c1.619,2.174,5.666,3.085,8.893,2.128c3.233-0.958,4.363-2.331,2.837-3.597c-1.526-1.264,1.972-3.939,7.891-4.867
			c2.721-0.423,5.603-0.81,8.291-1.139c5.943-0.729,5.976-1.905,0.009-2.37c-4.36-0.336-9.105-0.605-13.225-0.808
			c-5.979-0.292-11.834-1.859-13.461-3.228c-1.627-1.365-5.029-2.128-7.466-1.868c-2.44,0.256-3.13,1.216-2.017,2.653
			c1.11,1.438-2.977,2.522-8.956,2.653c-1.555,0.03-3.149,0.08-4.692,0.126c-5.987,0.18-12.287-1.529-14.596-3.368
			c-2.308-1.838-9.129-2.309-15.039-1.312l-0.786,0.134c-5.903,0.99-7.938,2.741-5.065,4.845c2.882,2.106,0.209,4.539-5.702,5.47
			c-2.372,0.381-4.877,0.785-7.229,1.18c-5.911,0.982-14.263-0.307-19.008-2.192c-4.736-1.894-12.557-1.866-17.368-0.203
			c-4.813,1.663-4.767,3.358-0.321,5.236c4.45,1.881,3.304,5.5-2.32,7.56l-14.12,5.173c-5.62,2.062-14.038,1.13-19.39-0.815
			c-5.354-1.947-14.208-0.981-19.595,1.625l-6.822,3.304c-5.386,2.615-4.899,5.071,0.635,7.259
			c5.537,2.192,5.677,7.019,0.579,10.167l-5.604,3.464c-5.093,3.148-12.974,2.998-18.33,1.12c-5.354-1.875-13.822-0.25-18.771,3.134
			l-12.982,8.887c-4.945,3.384-4.06,6.091,1.685,7.64c5.747,1.543,5.889,6.366,1.108,9.984c-24.9,18.849-59.09,57.067-73.681,73.867
			c-3.925,4.521-5.466,3.646-3.308-1.932c50.391-130.312,153.719-161.203,153.719-161.203
			c124.879-46.719,208.843-6.524,235.352,9.718C432.622,114.656,435.523,122.352,434.004,128.145z"
        />
      </g>
    </g>
  </svg>
	);
};

export default Rugby;
